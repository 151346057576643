<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-0">
      <div>
        <F130SlotsEdit
          :prop_editslot="editslot"
        />
        <F130SlotsDateien
          v-if="get.s"
          :s="parseInt(get.s, 10)"
        />
        <v-container>
          <v-btn
            v-if="
              this.$store.state.main.save.user.rechte.includes('x|130|000|adm')
              || this.$store.state.main.save.user.rechte.includes('x|130|000|010')
            "
            small
            class="me-1"
            color="primary"
            @click="createSlot();"
          >
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </v-container>
      </div>
      <v-card
        v-for="slot in store.slots"
        :key="slot.id"
        class="ma-4"
      >
        <i
          style="
            position:absolute;top:5px;
            font-size:70%;
            pointer-events:none;
          "
          class="ps-4"
        >
          {{ slot.sort }}
        </i>
        <v-card-title>
          <v-row>
            <v-col>
              <v-btn
                small text color="primary"
                @click="$router.push({ path: `130?s=${slot.id}` });"
              >
                {{ slot.name }}
              </v-btn>
            </v-col>
            <v-col align="right" cols="4">
              <v-menu
                v-if="
                  slot.bedingungen.f130.bearbeiten.nutzer
                  || slot.bedingungen.f130.sehen.nutzer
                "
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >mdi-menu</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-if="slot.bedingungen.f130.bearbeiten.nutzer"
                    @click="editslot = JSON.parse(JSON.stringify(slot));"
                  >
                    <v-list-item-title>
                      <v-icon left>mdi-pencil</v-icon>
                      Slot bearbeiten
                    </v-list-item-title>
                  </v-list-item>
                  <v-dialog
                    v-if="slot.bedingungen.f130.bearbeiten.nutzer"
                    v-model="dialog_delSlot[slot.id]"
                    width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-title>
                          <v-icon left>mdi-delete</v-icon>
                          Slot löschen
                        </v-list-item-title>
                      </v-list-item>
                    </template>

                    <v-card>
                      <v-card-title>
                        Sicher?
                      </v-card-title>

                      <v-card-text>
                        Sämtliche Dateien dieses Slots werden ebenfalls gelöscht.
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          text
                          @click="dialog_delSlot[slot.id] = false"
                        >
                          Nichts tun
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          text
                          @click="delSlot(slot);"
                          :loading="saving.delslot[slot.id]"
                        >
                          Slot löschen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <div hidden>{{ saving }}</div>
        </v-card-title>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import F130SlotsEdit from '@/components/Funktionen/100/F130Cloud/F130SlotsEdit.vue';
import F130SlotsDateien from '@/components/Funktionen/100/F130Cloud/F130SlotsDateien.vue';

export default {
  components: {
    F130SlotsEdit,
    F130SlotsDateien,
  },
  props: {
    sitecode: {
      type: String,
      default: '130',
    },
    get: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    store: {},
    lastLoadedStore: {},
    changedStore: {},
    editslot: { id: -1 },
    uebersichtslot: 0,
    dialog_delSlot: {},
  }),
  computed: {
    loadedStore: { // Databinding mit vuex state
      get() {
        return this.$store.state.f130.store;
      },
    },
    saving: { // Databinding mit vuex state
      get() {
        return this.$store.state.f130.saving;
      },
    },
  },
  watch: {
    loadedStore: {
      handler(neu) {
        /*
          Wenn settings in der Datenbank geändert wurde,
          lade diese neu ein.
        */
        if (JSON.stringify(neu.settings) !== this.lastLoadedStore.settings) {
          this.store.settings = neu.settings;
          this.lastLoadedStore.settings = JSON.stringify(neu.settings);
          this.changedStore.settings = false;
        }
        /*
          Wenn slots in der Datenbank geändert wurde,
          lade diese neu ein.
        */
        if (JSON.stringify(neu.slots) !== this.lastLoadedStore.slots) {
          this.store.slots = neu.slots;
          this.lastLoadedStore.slots = JSON.stringify(neu.slots);
          this.changedStore.slots = false;
        }
      },
      deep: true,
    },
    store: {
      handler(neu) {
        /*
          Prüfe ob settings lokal geändert wurde
        */
        if (JSON.stringify(neu.settings) !== this.lastLoadedStore.settings) {
          this.changedStore.settings = true;
        } else {
          this.changedStore.settings = false;
        }
      },
      deep: true,
    },
    /* get(neu) {
      if (neu.s) {
        console.log(`Öffne Slot ${neu.s}`);
      } else {
        console.log('Slot geschlossen');
      }
    }, */
  },
  methods: {
    createSlot() {
      this.editslot = { id: 0 };
    },
    delSlot(slot) {
      this.$store.dispatch('f130/tryGetOrSaveSettings', {
        dataToSave: {
          type: 'delslot',
          data: { delslot: slot },
          texte_success: 'Slot erfolgreich gelöscht.',
        },
      });
    },
  },
  created() {
    this.store = JSON.parse(JSON.stringify(this.$store.state[`f${this.sitecode}`].store));
    /* this.lastLoadedStore.v = JSON.stringify(this.$store.state[`f${this.sitecode}`].store.v);
    this.changedStore.v = false; */
    /*
      Lade settings aus vuex
    */
    this.lastLoadedStore.settings = JSON.stringify(this.$store.state[`f${this.sitecode}`].store.settings);
    this.changedStore.settings = false;
    /*
      Lade slots aus vuex
    */
    this.lastLoadedStore.slots = JSON.stringify(this.$store.state[`f${this.sitecode}`].store.slots);
    this.changedStore.slots = false;
  },
};
</script>
