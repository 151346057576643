<template>
  <div>
    <p class="title">
      Wähle Dateien aus, die hochgeladen werden sollen:
    </p>
    <v-container>
      <v-text-field
        label="Thema"
        hint="Beschreibe kurz, um was es sich bei diesem Upload handelt"
        v-model="thema"
      />
      <v-file-input
        label="Datei/en wählen..."
        multiple
        v-model="files"
      ></v-file-input>
      <div align="center">
        <v-btn
          :disabled="
            !files
            || files.length <= 0
            || thema.trim() === ''
          "
          @click="validateUpload();"
        >Hochladen</v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    upload4slot: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    files: null,
    thema: '',
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    validateUpload() {
      if (this.files.length <= 0) {
        //
      } else if (this.thema.trim() === '') {
        this.$store.commit('main/alert', {
          typ: 'error',
          text: 'Bitte gib ein Thema an',
        }, { root: true });
      } else {
        //console.log('Starte upload:');
        this.upload();
      }
    },
    async upload() {
      const anzahlFiles = this.files.length;
      let i = 1;
      this.$store.commit('main/switchIsSaving', true);

      for (const file of this.files) {
        const body = new FormData();
        body.append('code', this.$store.state.login.session.code);
        body.append('upload4slotid', this.upload4slot.id);
        body.append('dataToSave', JSON.stringify({
          type: 'uploadslot',
        }));
        this.thema = this.thema.trim();
        body.append('thema', this.thema);
        body.append('file', file);
        body.append('filesCount', i);
        body.append('filesCountMax', anzahlFiles);
        try {
          const response = await fetch(`${process.env.VUE_APP_API_HOST}/100/130/?getOrSaveSettings`, {
            method: 'post',
            body,
          });
          const res = await response.json();
          const updatedSlot = this.$store.state.f130.store.slots.filter((o) => o.id === this.upload4slot.id)[0];
          if (!Object.keys(updatedSlot.dateien).includes(this.thema)) {
            updatedSlot.dateien[this.thema] = [];
          }
          updatedSlot.dateien[this.thema].push(res.neu);
          this.$emit('loadDateien');
        } catch (err) {
          console.error(err);
        }
        i += 1;
      }
      this.$store.commit('main/switchIsSaving', false);
      this.files = [];
    }
  },
  created() {
  },
};
</script>
