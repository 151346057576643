var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editslot.id >= 0)?_c('v-dialog',{attrs:{"scrollable":"","width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[(_vm.editslot.id === 0)?_c('span',[_vm._v("Slot hinzufügen")]):_vm._e()]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.formvalid),callback:function ($$v) {_vm.formvalid=$$v},expression:"formvalid"}},[_c('v-text-field',{attrs:{"label":"Sortierungspräfix","hint":"Parameter zur Sortierung der Slots in der Übersicht","prepend-icon":"mdi-account","rules":[
            _vm.$store.state.main.inputValidationRules.required,
            _vm.$store.state.main.inputValidationRules.name ]},model:{value:(_vm.editslot.sort),callback:function ($$v) {_vm.$set(_vm.editslot, "sort", $$v)},expression:"editslot.sort"}}),_c('v-text-field',{attrs:{"label":"Name","hint":"Anzuzeigender Name des Slots","prepend-icon":"mdi-account","rules":[
            _vm.$store.state.main.inputValidationRules.required,
            _vm.$store.state.main.inputValidationRules.name ]},model:{value:(_vm.editslot.name),callback:function ($$v) {_vm.$set(_vm.editslot, "name", $$v)},expression:"editslot.name"}}),_c('v-row',{staticClass:"mt-4"},[_c('Bedingung',{attrs:{"text_titel":"Betrachter","text_beschreibung":"Welche Nutzer sollen alle Dateien\n            dieses Slot sehen dürfen?","text_alle":"Alle Nutzer sind Betrachter","text_gruppen":"Nur bestimmte Nutzer sind Betrachter"},model:{value:(_vm.editslot.bedingungen.f130.sehen),callback:function ($$v) {_vm.$set(_vm.editslot.bedingungen.f130, "sehen", $$v)},expression:"editslot.bedingungen.f130.sehen"}}),_c('Bedingung',{attrs:{"text_titel":"Datei-Ersteller","text_beschreibung":"Welche Nutzer sollen Dateien für diesen\n            Slot hinzufügen dürfen?","text_alle":"Alle Nutzer sind Datei-Ersteller","text_gruppen":"Nur bestimmte Nutzer sind Datei-Ersteller"},model:{value:(_vm.editslot.bedingungen.f130.upload),callback:function ($$v) {_vm.$set(_vm.editslot.bedingungen.f130, "upload", $$v)},expression:"editslot.bedingungen.f130.upload"}}),_c('Bedingung',{attrs:{"text_titel":"Slotadministrator","text_beschreibung":"Welche Nutzer sollen diesen Slot und die\n            enthaltenen Dateien bearbeiten und löschen dürfen?","text_alle":"Alle Nutzer sind Slotadministrator","text_gruppen":"Nur bestimmte Nutzer sind Slotadministrator"},model:{value:(_vm.editslot.bedingungen.f130.bearbeiten),callback:function ($$v) {_vm.$set(_vm.editslot.bedingungen.f130, "bearbeiten", $$v)},expression:"editslot.bedingungen.f130.bearbeiten"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" abbrechen ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.formvalid,"loading":_vm.$store.state.f130.saving.editslot},on:{"click":function($event){return _vm.$store.dispatch('f130/tryGetOrSaveSettings', { dataToSave: {
          type: 'editslot',
          data: { editslot: _vm.editslot },
        }, router: _vm.$router });}}},[_vm._v(" speichern ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }