<template>
  <v-dialog
    v-if="editslot.id >= 0"
    scrollable
    v-model="showDialog"
    width="500"
  >
    <v-card>
      <v-card-title>
        <span v-if="editslot.id === 0">Slot hinzufügen</span>
      </v-card-title>

      <v-card-text>
        <v-form v-model="formvalid">
          <v-text-field
            label="Sortierungspräfix"
            hint="Parameter zur Sortierung der Slots in der Übersicht"
            prepend-icon="mdi-account"
            v-model="editslot.sort"
            :rules="[
              $store.state.main.inputValidationRules.required,
              $store.state.main.inputValidationRules.name,
            ]"
          ></v-text-field>
          <v-text-field
            label="Name"
            hint="Anzuzeigender Name des Slots"
            prepend-icon="mdi-account"
            v-model="editslot.name"
            :rules="[
              $store.state.main.inputValidationRules.required,
              $store.state.main.inputValidationRules.name,
            ]"
          ></v-text-field>
          <v-row class="mt-4">
            <Bedingung
              v-model="editslot.bedingungen.f130.sehen"
              text_titel="Betrachter"
              :text_beschreibung="`Welche Nutzer sollen alle Dateien
              dieses Slot sehen dürfen?`"
              text_alle="Alle Nutzer sind Betrachter"
              text_gruppen="Nur bestimmte Nutzer sind Betrachter"
            />
            <Bedingung
              v-model="editslot.bedingungen.f130.upload"
              text_titel="Datei-Ersteller"
              :text_beschreibung="`Welche Nutzer sollen Dateien für diesen
              Slot hinzufügen dürfen?`"
              text_alle="Alle Nutzer sind Datei-Ersteller"
              text_gruppen="Nur bestimmte Nutzer sind Datei-Ersteller"
            />
            <Bedingung
              v-model="editslot.bedingungen.f130.bearbeiten"
              text_titel="Slotadministrator"
              :text_beschreibung="`Welche Nutzer sollen diesen Slot und die
              enthaltenen Dateien bearbeiten und löschen dürfen?`"
              text_alle="Alle Nutzer sind Slotadministrator"
              text_gruppen="Nur bestimmte Nutzer sind Slotadministrator"
            />
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          text
          @click="showDialog = false"
        >
          abbrechen
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          text color="primary"
          :disabled="!formvalid"
          :loading="$store.state.f130.saving.editslot"
          @click="$store.dispatch('f130/tryGetOrSaveSettings', { dataToSave: {
            type: 'editslot',
            data: { editslot },
          }, router: $router });"
        >
          speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Bedingung from '@/components/Inputs/Bedingung.vue';

export default {
  components: {
    Bedingung,
  },
  props: {
    prop_editslot: {
      type: Object,
      default() { return { id: 0 }; },
    },
  },
  data: () => ({
    showDialog: false,
    editslot: {
      id: -1,
    },
    formvalid: null,
  }),
  computed: {
    saving: { // Databinding mit vuex state
      get() {
        return this.$store.state.f130.saving;
      },
    },
  },
  watch: {
    prop_editslot: {
      handler(neu) {
        // console.log('editslot geändert:', neu);
        this.editslot = JSON.parse(JSON.stringify(neu));
        this.showDialog = true;
      },
      deep: true,
    },
    saving: {
      handler(neu) {
        if (neu.editslot === false) {
          this.showDialog = false;
        }
      },
      deep: true,
    },
    showDialog(neu) {
      if (neu === false) {
        this.editslot = {
          id: -1,
        };
      } else {
        this.neuSlot();
      }
    },
  },
  methods: {
    neuSlot() {
      if (this.editslot.id === 0) {
        let jahr = new Date();
        jahr = jahr.getFullYear();
        this.editslot = {
          id: 0,
          sort: jahr,
          name: '',
          bedingungen: {
            f130: {
              sehen: this.$store.state.f130.store.settings.standardbedingungen.f130.sehen,
              bearbeiten: this.$store.state.f130.store.settings.standardbedingungen.f130.bearbeiten,
              upload: this.$store.state.f130.store.settings.standardbedingungen.f130.upload,
            },
          },
          qr_code: '',
        };
      }
    },
  },
  created() {
  },
};
</script>
