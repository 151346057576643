<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="showDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span
            v-if="
              $store.state.f130.store.slots.filter((o) => o.id === idSlot).length > 0
            "
          >
            {{ $store.state.f130.store.slots.filter((o) => o.id === idSlot)[0].name }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!-- <v-btn
            dark
            text
          >
            Save
          </v-btn> -->
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pa-2">
        <div class="pa-3"
          v-if="
            bedingungen.upload.nutzer
            || bedingungen.bearbeiten.nutzer
          "
        >
          <F130SlotsDateienUpload
            :upload4slot="$store.state.f130.store.slots.filter((o) => o.id === idSlot)[0]"
            @loadDateien="loadDateien"
          />
        </div>
        <v-divider class="mb-4" />
        <div class="pa-3"
          v-if="
            slotdateien
          "
        >
          <p class="title">
            Dateien:
          </p>
          <div
            v-for="(dateien, thema) in slotdateien"
            class="mb-5"
          >
            <p class="subtitle-1">{{ thema }}</p>
            <v-list>
              <v-list-item
                v-for="datei in dateien"
              >
                <v-list-item-content class="py-0">
                  <v-list-item-title>
                    {{ datei.titel+'.'+datei.endung }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ datei.ident_login_upload+' | '+datei.date_lastMod_de }}
                  </v-list-item-subtitle>
                  <v-list-item-action>
                    <v-menu offset-y
                      v-if="
                        bedingungen.bearbeiten.nutzer
                        || (
                          bedingungen.upload.nutzer
                          && datei.ident_login_upload === $store.state.main.save.user.id
                        )
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <v-card class="pa-1" elevation="0">
                        Sicher?
                      </v-card>
                        <v-list>
                          <v-list-item color="ff0000"
                            @click="delDatei(datei.id, '')"
                          >
                            <v-list-item-title>Ja, Löschen</v-list-item-title>
                          </v-list-item>
                          <v-list-item link>
                            <v-list-item-title>Abbrechen</v-list-item-title>
                          </v-list-item>
                        </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import F130SlotsDateienUpload from '@/components/Funktionen/100/F130Cloud/F130SlotsDateienUpload.vue'

export default {
  components: {
    F130SlotsDateienUpload,
  },
  props: {
    s: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    showDialog: true,
    idSlot: null,
    slotdateien: {},
  }),
  computed: {
    saving: { // Databinding mit vuex state
      get() {
        return this.$store.state.f130.saving;
      },
    },
    bedingungen: { // Databinding mit vuex state
      get() {
        if (
          this.$store.state.f130.store.slots
            .filter((o) => o.id === this.idSlot).length > 0
        ) {
          return this.$store.state.f130.store.slots
            .filter((o) => o.id === this.idSlot)[0].bedingungen.f130;
        }
        return [];
      },
    },
  },
  watch: {
    showDialog(neu) {
      if (neu === false) {
        setTimeout(() => {
          this.$router.push({ path: '/130' });
        }, 200);
      }
    },
    saving: {
      handler(neu) {
        if (neu.editDatei === false) {
          this.loadDateien();
        }
      },
      deep: true,
    },
  },
  methods: {
    loadDateien() {
      //this.$store.dispatch(`f130/tryGetOrSaveSettings`, {});
      if (this.$store.state.f130.store.slots
        .filter((o) => o.id === this.idSlot).length > 0) {
        this.slotdateien = null;
        this.slotdateien = this.$store.state.f130.store.slots.filter((o) => o.id === this.idSlot)[0].dateien;
        console.log(this.slotdateien);
      }
    },
    async delDatei(idDatei, thema) {
      this.$store.commit('main/switchIsSaving', true);

      const body = new FormData();
      body.append('code', this.$store.state.login.session.code);
      body.append('idSlot', this.idSlot);
      body.append('dataToSave', JSON.stringify({
        type: 'deleteSlotDatei',
      }));
      if (thema.trim() !== '') {
        body.append('delThema', thema);
        body.append('deldatei-thema', thema);
      } else if (idDatei && idDatei > 0) {
        body.append('delDatei', idDatei);
        body.append('deldatei-id', idDatei);
      }
      try {
        const response = await fetch(`${process.env.VUE_APP_API_HOST}/100/130/?getOrSaveSettings`, {
          method: 'post',
          body,
        });
        const res = await response.json();
        const updatedSlot = this.$store.state.f130.store.slots.filter((o) => o.id === this.idSlot)[0];
        if (thema.trim() !== '') {
          delete updatedSlot.dateien[thema];
        } else if (idDatei) {
          Object.keys(updatedSlot.dateien).forEach((key) => {
            updatedSlot.dateien[key] = updatedSlot.dateien[key].filter((o) => o.id !== idDatei);
            if (updatedSlot.dateien[key].length <= 0) {
              delete updatedSlot.dateien[key];
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
      this.$emit('loadDateien');
      this.$store.commit('main/switchIsSaving', false);
    }
  },
  created() {
    this.idSlot = this.s;
    this.loadDateien();
  },
};
</script>
