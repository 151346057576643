import Vue from 'vue';
import Vuex from 'vuex';
import main from './modules/main';
import login from './modules/login';
import f001 from './modules/000/f001';
import f020 from './modules/000/f020';
import f110 from './modules/100/f110';
import f130 from './modules/100/f130';
import f150 from './modules/100/f150';
import f200 from './modules/200/f200';
import f420 from './modules/400/f420';
import f999 from './modules/f999';
import ftemplate from './modules/ftemplate';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    main,
    login,
    f001,
    f020,
    f110,
    f130,
    f150,
    f200,
    f420,
    f999,
    ftemplate,
  },
});
