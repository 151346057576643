import $ from 'jquery';

export default {
  namespaced: true,
  state: {
    sitecode: '130',
    loading: false,
    saving: {
      v: 0,
      settings: false,
      editslot: false,
      delslot: {},
    },
    offline: false,
    offlineHideAlert: false,
    store: {
      v: 0,
    },
  },
  getters: {
  },
  mutations: {
    offlineHideAlert(state) {
      state.offlineHideAlert = true;
    },
  },
  actions: {
    async tryGetOrSaveSettings({ state }, { dataToSave }) {
      let help = {};
      // console.log(dataToSave);
      if (dataToSave) {
        if (
          dataToSave.type === 'delslot'
        ) {
          state.saving[dataToSave.type][dataToSave.data[dataToSave.type].id] = true;
          state.saving.v += 1;
        } else {
          state.saving[dataToSave.type] = true;
        }
        this.commit('main/switchIsSaving', true);
      } else {
        state.loading = true;
        if (localStorage.getItem(`f${state.sitecode}`)) {
          help = JSON.parse(localStorage.getItem(`f${state.sitecode}`));
        }
      }
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/100/${state.sitecode}/?getOrSaveSettings`,
        type: 'post',
        data: {
          code: this.state.login.session.code,
          dataToSave: JSON.stringify(dataToSave),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.commit('login/backendErrorSession');
          } else {
            /* if (dataToSave) {
              if (dataToSave.texte_success) {
                this.commit('main/alert', {
                  typ: 'success',
                  text: dataToSave.texte_success,
                }, { root: true });
              } else {
                this.commit('main/alert', {
                  typ: 'success',
                  text: 'Speichern erfolgreich.',
                }, { root: true });
              }
            } */
            state.offline = false;
            // console.log(response);
            help.xtime = response.xtime;
            help.settings = response.settings;
            state.store.settings = help.settings;
            if (response.slots !== undefined) {
              help.slots = response.slots;
              state.store.slots = help.slots;
            }
            state.store.v += 1;
            localStorage.setItem(`f${state.sitecode}`, JSON.stringify(state.store));
          }
        },
        timeout: 10000,
        error: () => {
          state.offline = true;
          if (dataToSave) {
            this.commit('main/alert', {
              typ: 'error',
              text: 'Fehler! Bitte die Netzwerkverbindung prüfen.',
            }, { root: true });
          }
        },
        complete: () => {
          state.loading = false;
          if (dataToSave) {
            if (dataToSave.type === 'delslot') {
              state.saving.delslot[dataToSave.data.delslot.id] = false;
              state.saving.v += 1;
            } else {
              state.saving[dataToSave.type] = false;
            }
            this.commit('main/switchIsSaving', false);
          }
        },
      });
    },
  },
};
