var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[(
            _vm.$store.state.f130.store.slots.filter(function (o) { return o.id === _vm.idSlot; }).length > 0
          )?_c('span',[_vm._v(" "+_vm._s(_vm.$store.state.f130.store.slots.filter(function (o) { return o.id === _vm.idSlot; })[0].name)+" ")]):_vm._e()]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',{staticClass:"pa-2"},[(
          _vm.bedingungen.upload.nutzer
          || _vm.bedingungen.bearbeiten.nutzer
        )?_c('div',{staticClass:"pa-3"},[_c('F130SlotsDateienUpload',{attrs:{"upload4slot":_vm.$store.state.f130.store.slots.filter(function (o) { return o.id === _vm.idSlot; })[0]},on:{"loadDateien":_vm.loadDateien}})],1):_vm._e(),_c('v-divider',{staticClass:"mb-4"}),(
          _vm.slotdateien
        )?_c('div',{staticClass:"pa-3"},[_c('p',{staticClass:"title"},[_vm._v(" Dateien: ")]),_vm._l((_vm.slotdateien),function(dateien,thema){return _c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"subtitle-1"},[_vm._v(_vm._s(thema))]),_c('v-list',_vm._l((dateien),function(datei){return _c('v-list-item',[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(datei.titel+'.'+datei.endung)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(datei.ident_login_upload+' | '+datei.date_lastMod_de)+" ")]),_c('v-list-item-action',[(
                      _vm.bedingungen.bearbeiten.nutzer
                      || (
                        _vm.bedingungen.upload.nutzer
                        && datei.ident_login_upload === _vm.$store.state.main.save.user.id
                      )
                    )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('v-card',{staticClass:"pa-1",attrs:{"elevation":"0"}},[_vm._v(" Sicher? ")]),_c('v-list',[_c('v-list-item',{attrs:{"color":"ff0000"},on:{"click":function($event){return _vm.delDatei(datei.id, '')}}},[_c('v-list-item-title',[_vm._v("Ja, Löschen")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Abbrechen")])],1)],1)],1):_vm._e()],1)],1)],1)}),1)],1)})],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }